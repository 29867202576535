import React from 'react';
import { Row, Col, Card, ListGroup } from 'react-bootstrap';
import { formatIrishDateTime } from '../dashboard/EvaluationsTableColumns';
import Car_Icon from '../assets/car_icon.png'
import './TestDetails.css'

const TestDetails = ({ evaluation }) => {
    const carHeader = evaluation && evaluation.vehicle
        ? `${evaluation.vehicle?.year.value} ${evaluation.vehicle?.make?.value} ${evaluation.vehicle?.model?.value}`
        : 'Vehicle Details';

    return (
        <Card className='test-details-card' >
            <Card.Header
                className='test-details-header'
            >
                <p className='test-details-header-text'> {carHeader}</p>
                <img src={Car_Icon} alt="car icon" className='test-details-car-icon' />
            </Card.Header>
            <div className="test-details-header-border"></div>
            <Card.Body className='test-details-card-body'>
                <Row>
                    <Col md={6} lg={12}>
                        <ListGroup variant="flush" style={{ textAlign: 'left' }}>
                            <ListGroup.Item className='test-details-list-item'>
                                <span className='test-detail-info'>Report ID:</span>
                                <span className='test-detail-value'> {evaluation.code}</span>
                            </ListGroup.Item>
                            <ListGroup.Item className='test-details-list-item' >
                                <span className='test-detail-info'>Vehicle Make:</span>
                                <span className='test-detail-value'> {evaluation.vehicle?.make?.value}</span>
                            </ListGroup.Item>
                            <ListGroup.Item className='test-details-list-item' >
                                <span className='test-detail-info'>Date and Time:</span>
                                <span className='test-detail-value'> {formatIrishDateTime(evaluation.finished_at)} </span>
                            </ListGroup.Item>
                            <ListGroup.Item className='test-details-list-item'>
                                <span className='test-detail-info'>Year of Manufacture:</span>
                                <span className='test-detail-value'> {evaluation.vehicle?.year?.value} </span>
                            </ListGroup.Item>
                        </ListGroup>
                    </Col>
                    <Col md={6} lg={12}>
                        <ListGroup variant="flush" style={{ textAlign: 'left' }}>
                            <ListGroup.Item className='test-details-list-item' >
                                <span className='test-detail-info'>Vehicle Model:</span>
                                <span className='test-detail-value'> {evaluation.vehicle?.model?.value}</span>
                            </ListGroup.Item>
                            <ListGroup.Item className='test-details-list-item'>
                                <span className='test-detail-info'>Executed By:</span>
                                <span className='test-detail-value'> BatteryCycle Limited </span>
                            </ListGroup.Item>
                            <ListGroup.Item className='test-details-list-item'>
                                <span className='test-detail-info'>VIN:</span>
                                <span className='test-detail-value'> {evaluation.vehicle?.vin?.value}</span>
                            </ListGroup.Item>
                            <ListGroup.Item className='test-details-list-item'>
                                <span className='test-detail-info'>Mileage:</span>
                                <span className='test-detail-value'> {evaluation.vehicle?.odometer_distance?.value} Km
                                </span>
                            </ListGroup.Item>
                        </ListGroup>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
};

export default TestDetails;

const EvaluationStatus = {
    NOT_STARTED: 0,
    RUNNING: 1,
    SUCCEEDED: 2,
    FAILED: 3,
    FAILED_VEHICLE_ALREADY_RUNNING: 4
};

const DEFAULT_SMARTCAR_MODE = process.env.SMARTCAR_MODE || 'live'

export { EvaluationStatus, DEFAULT_SMARTCAR_MODE }
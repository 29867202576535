import { useState, useEffect } from 'react';
import { getSmartcarAuthURL } from './SmartcarUtils';
import ApiService from '../apis/ApiService';
import { evaluationStatuses } from '../dashboard/EvaluationsTableColumns';

const apiService = new ApiService(null, null, false);

export const useEvaluation = (evaluationCode, error, errorDescription) => {
    const [evaluation, setEvaluation] = useState(null)
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        if (evaluationCode && !error && !errorDescription) {
            function getEvaluation() {
                return apiService._get(`evaluations/${evaluationCode}/`)
                    .then(response => {
                        const response_evalution = response?.data
                        // If the evaluation hasn't yet been assigned a vehicle
                        if (response_evalution && response_evalution.status === evaluationStatuses.NOT_STARTED && !response_evalution.vehicle_id) {
                            // TODO: Maybe some caching can be made for all environment settings later to localStorage and only fetch seldom?
                            getSmartcarAuthURL(evaluationCode).then(smartcarAuthURL => {
                                window.location.href = smartcarAuthURL
                            })
                            // Keep loading spinner on, since this is an async action and takes a bit time. Otherwise error while be shown while there is no error!
                            return true;
                        } else {
                            setEvaluation(response_evalution);
                            return false;
                        }
                    })
                    .catch(error => {
                        console.error('Error fetching evaluation details:', error);
                        // Keep the spinner running if the error is aborted (ECONNABORTED)
                        if (error.code === 'ECONNABORTED') {
                            return true;
                        }

                        // Stop the spinner otherwise
                        return false; 
                    });
            }

            setIsLoading(true)
            getEvaluation().then(s => setIsLoading(s)).catch(() => setIsLoading(false))
        }
    }, [evaluationCode, error, errorDescription]);

    return { evaluation, setEvaluation, isLoading };
};

import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Dropdown, Image, Button } from 'react-bootstrap';
import { Nav } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import LogoHeader from './LogoHeader.jsx';
import { BiSolidChevronDown } from "react-icons/bi";
import './Header.css';
import SmartcarModeDropdown from './SmartcarModeDropdown.jsx';
import EnvironmentSettingsApi from '../apis/EnvironmentSettings.js';

const environmentSettingsApi = new EnvironmentSettingsApi(null, null, null)

const Header = ({ hideLogoutButton = false }) => {
  const navigate = useNavigate();
  const isAuthenticated = !!localStorage.getItem('token');
  const location = useLocation(); // 
  const hideSeparatorRoutes = ['/login', '/register', '/register-success', '/forgot-password', '/password-reset', '/'];
  const shouldHideSeparator = hideSeparatorRoutes.includes(location.pathname);

  const [isSmartcarModeChangingEnabled, setIsSmartcarModeChangingEnabled] = useState(false)

  useEffect(() => {
    environmentSettingsApi.getSmartcarModeChanging().then(r => setIsSmartcarModeChangingEnabled(r.data?.value))
  }, [])

  const handleLogout = () => {
    localStorage.removeItem('token');
    window.dispatchEvent(new Event('storage'));
    navigate('/login');
  };

  return (
    <header className="app-header">
      <Container>
        {isSmartcarModeChangingEnabled && <SmartcarModeDropdown />}
        <Row className="align-items-center">
          <Col>
            <div className="logo-container">
              <LinkContainer to="/">
                <Nav.Link><LogoHeader /></Nav.Link>
              </LinkContainer>
            </div>
          </Col>
          {isAuthenticated && !hideLogoutButton && (
            <Col className="text-right">
              <Button variant="outline-danger" onClick={handleLogout}>
                Logout
              </Button>
              {/* <Dropdown align="end">
                <Dropdown.Toggle as="div" id="profile-dropdown" className="profile-dropdown">
                  <div className="profile-container">
                    <Image
                      src="https://via.placeholder.com/40"
                      roundedCircle
                      className="profile-image"
                    />
                    <BiSolidChevronDown className="dropdown-icon" />
                  </div>
                </Dropdown.Toggle>

                <Dropdown.Menu className='dropdown-menue'>
                  <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown> */}
            </Col>
          )}
        </Row>
      </Container>
      <hr className={`separator ${shouldHideSeparator ? 'hide-separator-lg' : ''}`} />
    </header>
  );
};

export default Header;

import React from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { Nav } from 'react-bootstrap';
import successfulIcon from '../assets/successful.png'
import loginIcon from '../assets/loginicon.png'
import './RegisterSuccess.css';

const RegisterSuccess = () => {
    return (
        <div className="register-success-container">
            <div className="content">
                <div className="successful-icon-wrapper" >
                    <img src={successfulIcon} alt="successful icon" />
                </div>
                <h1 className='register-success-header'>Thank you for registering</h1>
                <p className='register-success-text'>
                    Check your inbox for a verification email to confirm your account
                </p>
                <div className="register-success-btn-container">
                    <LinkContainer to="/login">
                        <Nav.Link className='register-success-nav-link'>  <img src={loginIcon} alt="login icon" className='icon-login'/>  Go to Login</Nav.Link>
                    </LinkContainer>
                </div>
            </div>
        </div>
    );
};

export default RegisterSuccess;

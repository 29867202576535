export const vehicleTableColumns = [
    {
        name: '#',
        cell: (row, index) => index + 1,
        width: '5%',
    },
    {
        name: "VIN",
        selector: (row) => row?.id,
        style: { fontSize: '12px' },
        sortable: true,
        width: '35%',
    },
    {
        name: "Make",
        selector: (row) => row?.make?.value || '',
        style: { fontSize: '12px' },
        sortable: true,
        width: '20%',
    },
    {
        name: "Model",
        selector: (row) => row?.model?.value || '',
        style: { fontSize: '12px' },
        sortable: true,
        width: '20%',
    },
    {
        name: "Year",
        selector: (row) => row?.year?.value || '',
        style: { fontSize: '12px' },
        sortable: true,
        width: '20%',
    },
];

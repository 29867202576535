import React from 'react';
import logo from '../assets/logo-no-background.png';
import './LogoHeader.css';

const LogoHeader = () => {

  return (
    <img src={logo} alt="Header Logo" className="logo-header" style={{ width: '150px' }} />
  );
};

export default LogoHeader;

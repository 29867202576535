import axios from 'axios';
import { joinUrl } from '../util';

class ApiService {
    constructor(navigate, fallbackRoute, authProtected = true) {
        this.setNavigationWithFallbackRoute(navigate, fallbackRoute);
        this.authProtected = authProtected;
        this.BACKEND_HOST = process.env.REACT_APP_BACKEND_HOST;
        if (!this.BACKEND_HOST) {
            console.error('REACT_APP_BACKEND_HOST is not set');
            throw new Error('REACT_APP_BACKEND_HOST is not set');
        }
    }

    setNavigationWithFallbackRoute(navigate, fallbackRoute) {
        this.navigate = navigate;
        this.fallbackRoute = fallbackRoute;
    }

    isNavigationSet() {
        return this.navigate && this.fallbackRoute;
    }

    getToken() {
        return localStorage.getItem('token');
    }

    handleUnauthorized() {
        localStorage.removeItem('token');
        this.navigate(this.fallbackRoute);
    }

    async _call(method, url, data) {
        const axios_params = {
            method,
            url: joinUrl(this.BACKEND_HOST, url),
            data
        }

        if (this.authProtected) {
            const token = this.getToken();
            if (!token) {
                if (this.isNavigationSet()) this.handleUnauthorized();
                return Promise.reject(new Error('No auth token found in session storage'));
            }

            axios_params.headers = {
                Authorization: `Bearer ${token}`
            }
        }

        try {
            return await axios.request(axios_params);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                if (this.authProtected && this.isNavigationSet()) this.handleUnauthorized();
            }
            throw error;
        }
    }

    _get(url) {
        return this._call('get', url);
    }

    _post(url, data = {}) {
        return this._call('post', url, data);
    }
}

export default ApiService;

import React, { useState, useEffect } from 'react';
import { Dropdown } from 'react-bootstrap';
import EnvironmentSettingsApi from '../apis/EnvironmentSettings';
import { capitalizeFirstLetter } from '../util';
import { DEFAULT_SMARTCAR_MODE } from '../consts';

const environmentSettingsApi = new EnvironmentSettingsApi(null, null, null);
function SmartcarModeDropdown() {
    const [selected, setSelected] = useState(null);
    const selectedMode = selected || DEFAULT_SMARTCAR_MODE;

    useEffect(() => {
        environmentSettingsApi.getSmartcarMode()
            .then((response) => {
                setSelected(response.data?.value);
            })
            .catch((error) => {
                console.error("Error fetching Smartcar mode:", error);
            });
    }, []);

    const handleSelect = async (eventKey) => {
        setSelected(eventKey);

        try {
            await environmentSettingsApi.updateSmartcarMode(eventKey);
        } catch (error) {
            console.error("Error updating Smartcar mode:", error);
        }
    };

    return (
        <Dropdown onSelect={handleSelect} className={"smartcar-mode-dropdown"}>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
                {capitalizeFirstLetter(selectedMode)}
            </Dropdown.Toggle>

            <Dropdown.Menu>
                <Dropdown.Item active={selected === 'live'} eventKey="live">Live</Dropdown.Item>
                <Dropdown.Item active={selected === 'simulated'} eventKey="simulated">Simulated</Dropdown.Item>
                <Dropdown.Item active={selected === 'test'} eventKey="test">Test</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
}

export default SmartcarModeDropdown;

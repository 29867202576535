import { useState } from 'react';
import { Row, Col, InputGroup, Button, Toast, Form } from 'react-bootstrap';
import { Formik, Field, ErrorMessage } from 'formik';
import { LinkContainer } from 'react-router-bootstrap';
import * as Yup from 'yup';
import ChevronBackward from '../assets/chevron_backward.png';
import homeimg from '../assets/homeimg.png';
import VisibilityOnIcon from '../assets/visibility_on.png';
import VisibilityOffIcon from '../assets/visibility_off.png';
import './PasswordReset.css';


const validationSchema = Yup.object().shape({
    password: Yup.string().required('Password is required'),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Confirm Password is required'),
});

const PasswordReset = () => {
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
    const [showErrorToast, setShowErrorToast] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const togglePasswordVisibility = () => setPasswordVisible(!passwordVisible);
    const toggleConfirmPasswordVisibility = () => setConfirmPasswordVisible(!confirmPasswordVisible);

    const handleSubmit = async (values) => {
    
    };

    return (
        <Row className="rbh-container">
            <Col>
                <Row className="text-start align-items-start">
                    <Col md={12} lg={6} className="rbh-form-container login-container">
                        <div className='back-login-wrapper'>
                            <LinkContainer to="/login">
                                <img
                                    src={ChevronBackward}
                                    alt="chevron icon"
                                    className='back-login-icon'
                                />
                            </LinkContainer>
                            <p className='back-login-wrappe-text' style={{ cursor: "pointer" }}>Back to Login page</p>
                        </div>

                        <div className="forget-password-text-wrapper">
                            <h1 className='forget-password-header'>Enter a new password for your account</h1>
                            <p className='forget-password-text'>All fields are mandatory</p>
                        </div>

                        <Formik
                            initialValues={{ password: '', confirmPassword: '' }}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}
                        >
                            {({ handleSubmit, values, setFieldValue, touched, errors }) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                    <Form.Group className="form-group-custom">
                                        <Form.Label htmlFor="password" className="auth-form-label">Password</Form.Label>
                                        <InputGroup className="input-with-icon">
                                            <Field
                                                id="password"
                                                autoComplete="new-password"
                                                type={passwordVisible ? "text" : "password"}
                                                name="password"
                                                className={`form-control auth-form-control ${touched.password && errors.password ? 'is-invalid' : ''}`}
                                                placeholder="Enter your password"
                                                value={values.password}
                                                onChange={(e) => setFieldValue('password', e.target.value)}
                                            />
                                            {!(touched.password && errors.password) && (
                                                <div className="input-icon" onClick={togglePasswordVisibility}>
                                                    <img
                                                        src={passwordVisible ? VisibilityOnIcon : VisibilityOffIcon}
                                                        alt="toggle visibility"
                                                        className="visibility-icon"
                                                    />
                                                </div>
                                            )}
                                        </InputGroup>
                                        <ErrorMessage component="div" name="password" className="invalid-feedback" />
                                    </Form.Group>

                                    <Form.Group className="form-group-custom">
                                        <Form.Label htmlFor="confirmPassword" className="auth-form-label">Confirm Password</Form.Label>
                                        <InputGroup className="input-with-icon">
                                            <Field
                                                id="confirmPassword"
                                                autoComplete="new-password"
                                                type={confirmPasswordVisible ? "text" : "password"}
                                                name="confirmPassword"
                                                className={`form-control auth-form-control ${touched.confirmPassword && errors.confirmPassword ? 'is-invalid' : ''}`}
                                                placeholder="Confirm your password"
                                                value={values.confirmPassword}
                                                onChange={(e) => setFieldValue('confirmPassword', e.target.value)}
                                            />
                                            {!(touched.confirmPassword && errors.confirmPassword) && (
                                                <div onClick={toggleConfirmPasswordVisibility}>
                                                    <img
                                                        src={confirmPasswordVisible ? VisibilityOnIcon : VisibilityOffIcon}
                                                        alt="toggle visibility"
                                                        className="visibility-icon"
                                                    />
                                                </div>
                                            )}
                                        </InputGroup>
                                        <ErrorMessage component="div" name="confirmPassword" className="invalid-feedback" />
                                    </Form.Group>

                                    <Button type="submit" className="btn btn-primary w-100">Reset Password</Button>
                                </Form>
                            )}
                        </Formik>
                    </Col>
                </Row>
            </Col>
            <Col className='d-none d-lg-block'>
                <div className='layout_image_wrapper'>
                    <img src={homeimg} alt="home page image" />
                </div>
            </Col>

            <div className="toast-container-center">
                <Toast show={showErrorToast} onClose={() => setShowErrorToast(false)} className="toast-error">
                    <Toast.Header>
                        <strong className="me-auto">Error</strong>
                    </Toast.Header>
                    <Toast.Body>
                        {errorMessage}
                    </Toast.Body>
                </Toast>
            </div>
        </Row>
    );
};

export default PasswordReset;

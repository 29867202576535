function joinUrl(base, ...paths) {
    // Remove trailing slash from base if present
    if (base.endsWith('/')) {
        base = base.slice(0, -1);
    }

    // Join all paths and ensure single slashes between them
    const joinedPaths = paths.map(path => {
        // Ensure path starts with a single slash
        if (path.startsWith('/')) {
            path = path.slice(1);
        }
        // Ensure path does not end with a slash
        if (path.endsWith('/')) {
            path = path.slice(0, -1);
        }
        return path;
    }).join('/');

    return `${base}/${joinedPaths}`;
}

function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
};

export { joinUrl, capitalizeFirstLetter };
import React, { useState } from 'react';
import { Row, Col, Dropdown, Card } from 'react-bootstrap';
import { QRCodeCanvas } from 'qrcode.react';
import SohChart from './SohChart';
import SOHDescription from './SOHDescription';
import ScoreIcon from '../assets/score.png';
import { BiSolidChevronDown } from "react-icons/bi";
import { MdOutlineFileDownload } from "react-icons/md";
import './ReportPage.css'
import './BatteryHealthScore.css'

const BatteryHealthScore = ({ evaluation }) => {
    const [fileType, setFileType] = useState('PDF');

    const handleDownload = () => {
        console.log('download');
    };

    return (
        <Card className='bhs-details-card'>
            <Card.Header
                className='test-details-header'
            >
                <p className='test-details-header-text'>Battery Health Score</p>
                <img src={ScoreIcon} alt="car icon" className='test-details-car-icon' />
            </Card.Header>
            <div className="test-details-header-border"></div>
            <Card.Body className='test-details-card-body'>
                <Row className="mt-1">
                    <Col xs={12} md={12}>
                        <SohChart soh={evaluation?.report?.soh} />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={8}>
                        <SOHDescription soh={evaluation?.report?.soh} />
                    </Col>
                    <Col xs={12} md={4} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <QRCodeCanvas className='custom-margin-sm' value={window.location.href} size={100} />
                    </Col>
                    {/* <Col xs={6} md={12} className="d-flex flex-column align-items-start download-report-col">
                                <Dropdown>
                                    <Dropdown.Toggle className='report-file-type-btn'>
                                        {fileType} <BiSolidChevronDown />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={() => setFileType('PDF')}>PDF</Dropdown.Item>
                                        <Dropdown.Item onClick={() => setFileType('DOCX')}>DOCX</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                                <button onClick={handleDownload} className="btn btn-primary mt-2" style={{ minHeight: "30px", minWidth: "150px", fontSize: "14px", display: 'flex', alignItems: 'center', padding: '0 4px' }}>
                                    <MdOutlineFileDownload style={{ fontSize: '18px', marginRight: '4px' }} />
                                    Download Report
                                </button>
                            </Col> */}
                </Row>
            </Card.Body>
        </Card>
    );
}

export default BatteryHealthScore
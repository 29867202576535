import React, { useEffect, useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import EvaluationsApi from '../apis/Evaluations';
import SuccessIcon from './success.png'
import './ThankYouPage.css'

const evaluationsApi = new EvaluationsApi(null, null, false)

const ThankYouPage = ({ evaluation }) => {
    const [ownerName, setOwnerName] = useState(null)

    useEffect(() => {
        evaluationsApi.getOwner(evaluation.code).then(r => setOwnerName(r?.data?.name))
    }, [evaluation.code])

    return ownerName ? (
        <div className="rbh-container test-complete-screen">
            <Row className="text-center justify-content-center">
                <Col md={8}>
                    <img src={SuccessIcon} alt="success icon " className='test-complete-img' />
                    <h1 className='test-complete-header'>Battery Health Test Complete</h1>
                    <p className='test-complete-text'> The report is being processed. When completed it will be sent to {ownerName}.</p>
                    <p className='test-complete-text'>The report provides an estimate of the State of Health (SoH) of your battery.</p>
                    {/* TODO: Change this to Alert (will need a change in Figma too) */}
                    <Button variant="primary" className="mt-4 close-window-btn w-100" onClick={() => { }}>
                        Close
                    </Button>
                </Col>
            </Row>
        </div>
    ) : null
};

export default ThankYouPage;

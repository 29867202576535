import ApiService from './ApiService';

class EnvironmentSettingsApi extends ApiService {
    constructor(navigate, fallbackRoute, authProtected = true) {
        super(navigate, fallbackRoute, authProtected);
    }

    get(key) {
        return this._get(`/environment_settings/${key}`);
    }

    getSmartcarMode() {
        return this.get("smartcar_mode");
    }

    getSmartcarModeChanging() {
        return this.get("smartcar_mode_changing");
    }

    update(key, value) {
        console.log("call updt", '/environment_settings', { key, value })
        return this._post('/environment_settings', { key, value });
    }

    updateSmartcarMode(value) {
        return this.update("smartcar_mode", value);
    }
}

export default EnvironmentSettingsApi;

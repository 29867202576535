import React, { useState } from 'react';
import { Form, Button, Alert, Nav } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import '../shared/form-elements.css';
import ApiService from '../apis/ApiService';
import { InputGroup } from 'react-bootstrap';
import VisibilityOnIcon from '../assets/visibility_on.png';
import VisibilityOffIcon from '../assets/visibility_off.png';

const validationSchema = Yup.object().shape({
    email: Yup.string()
        .email('Invalid email format')
        .matches(
            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
            'Invalid email format'
        )
        .required('Email is required'),
    password: Yup.string().required('Password is required'),
});

const apiService = new ApiService(null, null, false);

const LoginForm = () => {
    const [error, setError] = useState('');
    const [passwordVisible, setPasswordVisible] = useState(false);

    const navigate = useNavigate();

    const handleSubmit = async (values, { setSubmitting }) => {
        setError('');
        try {
            const params = new URLSearchParams();
            params.append('username', values.email);
            params.append('password', values.password);

            const response = await apiService._post('token/', params);
            if (response.status === 200) {
                const { access_token } = response.data;
                localStorage.setItem('token', access_token);
                navigate('/dashboard');
                window.dispatchEvent(new Event('storage'));
            }
        } catch (error) {
            console.error('Error during login:', error);
            const errorMsg = error.response?.data?.detail || 'An error occurred';
            setError(typeof errorMsg === 'string' ? errorMsg : JSON.stringify(errorMsg));
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <Formik
            initialValues={{
                email: '',
                password: '',
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            {({ handleSubmit, handleChange, values, touched, errors, isSubmitting }) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <Form.Group className="form-group-custom">
                        <Form.Label htmlFor="email" className="auth-form-label">Email</Form.Label>
                        <Field
                            id="email"
                            autoComplete="email"
                            type="email"
                            name="email"
                            placeholder="Enter your email address"
                            value={values.email || ''}
                            onChange={handleChange}
                            className={`form-control auth-form-control ${touched.email && errors.email ? 'is-invalid' : ''}`}
                        />
                        <ErrorMessage component="div" name="email" className="invalid-feedback" />
                    </Form.Group>

                    <Form.Group className="form-group-custom">
                        <Form.Label htmlFor="password" className="auth-form-label">Password</Form.Label>
                        <InputGroup className="input-with-icon">
                            <Field
                                id="password"
                                autoComplete="new-password"
                                type={passwordVisible ? "text" : "password"}
                                name="password"
                                className={`form-control auth-form-control ${touched.password && errors.password ? 'is-invalid' : ''}`}
                                placeholder="Enter your password"
                                value={values.password}
                                onChange={handleChange}
                            />
                            {!(touched.password && errors.password) && (
                                <div className="input-icon" onClick={() => setPasswordVisible(!passwordVisible)}>
                                    <img
                                        src={passwordVisible ? VisibilityOnIcon : VisibilityOffIcon}
                                        alt="toggle visibility"
                                        className="visibility-icon"
                                    />
                                </div>
                            )}
                        </InputGroup>
                        <ErrorMessage component="div" name="password" className="invalid-feedback" />
                    </Form.Group>

                    {error && (
                        <Alert variant="danger" className="mt-3">
                            {error}
                        </Alert>
                    )}

                    {/* <div className="forget-password-wrapper">
                        <LinkContainer to="/forgot-password">
                            <Nav.Link className='register-page-nav-link'>Forgot password?</Nav.Link>
                        </LinkContainer>
                    </div> */}

                    <Button variant="primary" type="submit" disabled={isSubmitting} className="mt-3 w-100">
                        {isSubmitting ? 'Logging in...' : 'Log In'}
                    </Button>
                </Form>
            )}
        </Formik>
    );
};

export default LoginForm;

import React from 'react';
import { useLocation } from 'react-router-dom';
import Header from './header/Header';

const Layout = ({ children }) => {
    const location = useLocation();
    const hideHeaderRoutes = [];
    const hideLogoutButtonRoutes = ['/evaluations/', '/callback-error/', '/report/'];
    const shouldHideHeader = hideHeaderRoutes.some(route => location.pathname.startsWith(route));
    const shouldHideLogoutButton = hideLogoutButtonRoutes.some(route => location.pathname.startsWith(route));
    console.log("location.pathname", location.pathname, shouldHideLogoutButton)

    return (
        <div className="App">
            {!shouldHideHeader && <Header hideLogoutButton={shouldHideLogoutButton} />}
            <div className="App-body">
                {children}
            </div>
        </div>
    );
};

export default Layout;

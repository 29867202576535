import ApiService from './ApiService';

class UsersApi extends ApiService {
    constructor(navigate, fallbackRoute, authProtected = true) {
        super(navigate, fallbackRoute, authProtected);
    }

    is_active(verification_guid) {
        return this._get(`/users/${verification_guid}/is_active`)
    }

    activate(verification_guid) {
        return this._post(`/users/${verification_guid}/activate`)
    }
}

export default UsersApi;

import { LinkContainer } from 'react-router-bootstrap';
import { Nav } from 'react-bootstrap';
import './AuthNav.css'

const AuthNav = ({ label, explanatoryText, linkTo }) => {
    return <div className='auth-navigate-wrapper'>
        <p>{explanatoryText}
            <LinkContainer to={linkTo}>
                <Nav.Link className='auth-nav-link'>{label}</Nav.Link>
            </LinkContainer>
        </p>
    </div>
}

export default AuthNav
import { useState, useEffect } from 'react';
import EvaluationsApi from '../apis/Evaluations';

const PROGRESS_BAR_UPDATE_INTERVAL_SECONDS = 60;
const PROGRESS_START_PERCENTAGE = 1;
const evaluationsApi = new EvaluationsApi(null, null, false);

const useEvaluationProgress = (evaluationCode, setEvaluation) => {
    const [progress, setProgress] = useState(0);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        let isActive = true;
        let timeoutId;

        const fetchAndUpdateProgress = async () => {
            if (!isActive || !evaluationCode) return;

            try {
                setIsLoading(true);

                const response_progress = await evaluationsApi.progress(evaluationCode);
                setProgress(response_progress.data);

                const response_evaluation = await evaluationsApi.get(evaluationCode);
                setEvaluation(response_evaluation.data);
            } catch (error) {
                console.error(error);
            } finally {
                setIsLoading(false);
            }

            if (isActive) {
                timeoutId = setTimeout(fetchAndUpdateProgress, PROGRESS_BAR_UPDATE_INTERVAL_SECONDS * 1000);
            }
        };

        fetchAndUpdateProgress();

        return () => {
            isActive = false;
            clearTimeout(timeoutId);
        };
    }, [evaluationCode, setEvaluation]);

    return {
        isLoading,
        progress: progress >= PROGRESS_START_PERCENTAGE ? progress : PROGRESS_START_PERCENTAGE
    };
};

export default useEvaluationProgress;

import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { joinUrl } from '../util';
import ApiService from '../apis/ApiService';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const apiService = new ApiService(null, null, false);

const SmartcarCallback = () => {
    const [isLoading, setIsLoading] = useState(true);
    const query = useQuery();
    const code = query.get('code');
    const state = (query.get('state') ? JSON.parse(atob(query.get('state'))).originalState : null) || '';
    const error = query.get('error');
    const errorDescription = query.get('error_description');

    useEffect(() => {
        if (error && errorDescription) {
            window.location.href = joinUrl(window.location.origin, `evaluations/${state}`) + `?error=${error}&error_description=${errorDescription}`
        } else if (code && state) {
            apiService._post('exchange_authorization_code/', { authorization_code: code, evaluation_code: state })
                .then(r => {
                    if (!r.data?.already_registered) {
                        window.location.href = joinUrl(window.location.origin, "evaluations", state);
                        setIsLoading(false);
                    }
                });
        } else {
            // If neither `code` nor `error` is present, just set loading to false
            setIsLoading(false);
        }
    }, [code, state, error, errorDescription]);

    if (isLoading) {
        return <div className="spinner">Loading...</div>;
    }

    return null;
}

export default SmartcarCallback;

import Smartcar from '@smartcar/auth';
import EnvironmentSettingsApi from '../apis/EnvironmentSettings';
import { DEFAULT_SMARTCAR_MODE } from '../consts';

const environmentSettingsApi = new EnvironmentSettingsApi(null, null, null)

export const getSmartcarAuthURL = evaluationCode => {
    return environmentSettingsApi.getSmartcarMode().then(r => {
        const smart_car_config = {
            clientId: process.env.REACT_APP_SMARTCAR_CLIENT_ID,
            redirectUri: process.env.REACT_APP_SMARTCAR_REDIRECT_URI,
            scope: [
                "read_vehicle_info",
                "read_odometer",
                "read_speedometer",
                "read_battery",
                "read_charge",
                "read_charge_locations",
                "read_compass",
                "read_location",
                "read_extended_vehicle_info",
                "read_vin"
            ],
            mode: r.data?.value || DEFAULT_SMARTCAR_MODE,
            onComplete: () => { },
        };
        const smartcar = new Smartcar(smart_car_config);
        return smartcar.getAuthUrl({ state: evaluationCode, singleSelect: true });
    })
};

import React from 'react';

const EvaluationError = ({ evaluationCode, error, errorDescription }) => {
    return (
        <div>
            <h1>Error</h1>
            {error && (
                <>
                    <p><strong>Error:</strong> {error}</p>
                </>
            )}
            {errorDescription && (
                <>
                    <p><strong>Description:</strong> {errorDescription}</p>
                </>
            )}
            {evaluationCode && (
                <p><strong>Evaluation Code:</strong> {evaluationCode}</p>
            )}
        </div>
    );
};

export default EvaluationError;

import React from 'react';
import { GaugeComponent } from 'react-gauge-component'
import { minSOHValue, maxSOHValue, poorHealthLimits, fairHealthLimits, goodHealthLimits, excellentHealthLimits } from './SOHConfig'
import './SohChart.css'

const Red = '#DC4437';
const Orange = '#E57C00';
const Yellow = '#F2C94C';
const Green = '#249101';

const formatTextValue = value => {
    return `${value}%`
}

const SohChart = ({ soh }) => {
    const stateOfHealthValue = soh || 0
    return <GaugeComponent
        type='semicircle'
        pointer={{ hide: true }}
        arc={{
            width: 0.2,
            padding: 0.005,
            cornerRadius: 1,
            subArcs: [
                {
                    limit: poorHealthLimits.upper_limit,
                    color: Red,
                    showTick: true,
                    tooltip: {
                        text: "Poor Battery Health"
                    }
                },
                {
                    limit: fairHealthLimits.upper_limit,
                    color: Orange,
                    showTick: true,
                    tooltip: {
                        text: "Fair Battery Health"
                    }
                },
                {
                    limit: goodHealthLimits.upper_limit,
                    color: Yellow,
                    showTick: true,
                    tooltip: {
                        text: "Good Battery Health"
                    }
                },
                {
                    limit: excellentHealthLimits.upper_limit,
                    color: Green,
                    showTick: true,
                    tooltip: {
                        text: "Excellent Battery Health"
                    }
                }
            ]
        }}
        labels={{
            valueLabel: {
                formatTextValue,
                matchColorWithArc: true,
                maxDecimalDigits: 2,
                style: {
                    textShadow: null,
                    fontWeight: "bold"
                }
            },
            tickLabels: {
                type: 'outer',
                defaultTickValueConfig: {
                    formatTextValue: (value) => value + '%',
                    style: { fontSize: 13 }
                },
            }
        }}
        value={stateOfHealthValue}
        minValue={minSOHValue}
        maxValue={maxSOHValue}
    />
};

export default SohChart;

const minSOHValue = 50
const maxSOHValue = 100
const poorHealthLimits = {
    lower_limit: 50, // inclusive
    upper_limit: 70, // not inclusive
}
const fairHealthLimits = {
    lower_limit: 70, // inclusive
    upper_limit: 85, // not inclusive
}
const goodHealthLimits = {
    lower_limit: 85, // inclusive
    upper_limit: 90, // not inclusive
}
const excellentHealthLimits = {
    lower_limit: 90, // inclusive
    upper_limit: 100, // not inclusive
}

const healthLimits = [poorHealthLimits, fairHealthLimits, goodHealthLimits, excellentHealthLimits]

export { minSOHValue, maxSOHValue, poorHealthLimits, fairHealthLimits, goodHealthLimits, excellentHealthLimits, healthLimits }
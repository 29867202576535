import React from 'react';
import { Row, Col } from 'react-bootstrap';
import HomeForm from './HomeForm';
import homeimg from '../assets/homeimg.png';
import '../shared/form-containers.css'

const Home = () => {
  return (
    <Row className="rbh-container home-container">
      <Col>
        <Row className="text-start align-items-start">
          <Col  md={12} lg={6} className="rbh-form-container home-screen-text-wrapper">
            <HomeForm />
          </Col>
        </Row>
      </Col>
      <Col className='d-none d-lg-block'>
        <div className='layout_image_wrapper '>
          <img src={homeimg} alt="home page image" />
        </div>
      </Col>
    </Row>
  );
};

export default Home;

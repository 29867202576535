import React, { useState } from 'react';
import { Row, Col, Button, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';

import '../shared/form-containers.css';
import EvaluationsApi from '../apis/Evaluations';
import InfoIcon from './info.png';
import './CharginDetailPage.css';

const FormTooltip = ({ label, tooltip_text }) => {
    return (
        <div className="d-flex align-items-center">
            <Form.Label>{label}</Form.Label>
            <OverlayTrigger
                placement="top"
                overlay={
                    <Tooltip id={`${label.replace(/\s/g, '')}Tooltip`}>
                        {tooltip_text}
                    </Tooltip>
                }
            >
                <img src={InfoIcon} alt="info icon" className="info_icon ms-2" />
            </OverlayTrigger>
        </div>
    );
};

const ChargingDetailsPage = ({ evaluation, setEvaluation }) => {
    const [kWhUsed, setKWhUsed] = useState('');
    const [userEndTime, setUserEndTime] = useState('');
    const evaluationsApi = new EvaluationsApi(null, null, false);

    const handleSubmit = () => {
        evaluationsApi.finish(evaluation.code, kWhUsed, userEndTime).then(r => {
            setEvaluation(r.data);
        });
    };

    const handleKWhUsedChange = e => {
        const value = e.target.value;
        if (value === '' || Number(value) >= 0) {
            setKWhUsed(value);
        }
    };

    const handleUserEndTimeChange = e => {
        setUserEndTime(e.target.value);
    };

    return (
        <div className="rbh-container battery-test-completion">
            <Row className="text-start align-items-start">
                <Col md={12}>
                    <h1 className="battery-test-completion-header">Charging Data</h1>
                    <p className="battery-test-completion-text-first">
                        We've captured enough data to complete the test, we will stop collecting data when you stop charging and complete the test.
                    </p>
                    <div className="row flex" style={{ justifyContent: "center" }}>
                        <p className="battery-test-completion-text-second">
                            To complete the test, stop charging and fill in the details on the charging session below
                        </p>
                    </div>
                    <Form>
                        <Form.Group controlId="kWhUsed" className="mt-3">
                            <FormTooltip label="kWh Used in Charging Session" tooltip_text="Specify the amount of energy used in this charging session in kilowatt-hours (kWh)." />
                            <Form.Control
                                type="number"
                                placeholder="in kWh"
                                value={kWhUsed}
                                onChange={handleKWhUsedChange}
                                min="0"
                            />
                        </Form.Group>
                        <Form.Group controlId="userEndTime" className="mt-3">
                            <FormTooltip label="End Time of the Charging Session" tooltip_text="Enter the exact time the charging session ended." />
                            <Form.Control
                                type="time"
                                value={userEndTime}
                                onChange={handleUserEndTimeChange}
                            />
                        </Form.Group>
                        <Row className="mt-4">
                            <Col className="text-end">
                                <Button variant="primary" className="w-100" onClick={handleSubmit}>
                                    Submit
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </div>
    );
};

export default ChargingDetailsPage;

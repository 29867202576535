import React from 'react';
import { Alert } from 'react-bootstrap';
import DataTable from "react-data-table-component";
import { vehicleTableColumns } from './VehiclesTableColumns';

const VehiclesTable = ({ vehicles, error }) => {
    return (
        <div>
            {error && <Alert variant="danger">{error}</Alert>}
            <div className='table-container'>
                <DataTable
                    keyField='id'
                    columns={vehicleTableColumns}
                    data={vehicles}
                    pagination
                    striped
                    paginationPerPage={5}
                    paginationRowsPerPageOptions={[5, 10, 20, 30, 50]}
                />
            </div>
        </div>
    );
};

export default VehiclesTable;

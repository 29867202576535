import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col, Container } from 'react-bootstrap';
import EvaluationsApi from '../apis/Evaluations';
import TestDetails from './TestDetails';
import './ReportPage.css';
import BatteryHealthScore from './BatteryHealthScore';

const evaluationsApi = new EvaluationsApi(null, null, false);


const ReportPage = () => {
    const { evaluationCode } = useParams();
    const [evaluation, setEvaluation] = useState(null);

    useEffect(() => {
        evaluationsApi
            .getForReport(evaluationCode)
            .then((r) => setEvaluation(r.data))
            .catch((error) => console.error('Error fetching evaluation details:', error));
    }, [evaluationCode]);

    return evaluation ? (
        <Container>
            <h1 className='report-score-header'>Battery Health Test Report</h1>
            <Row>
                <Col xs={12} md={6}>
                    <TestDetails evaluation={evaluation} />
                </Col>
                <Col xs={12} md={6}>
                    <BatteryHealthScore evaluation={evaluation} />
                </Col>
            </Row>
        </Container>
    ) : (
        <div>
            <h1 style={{ fontSize: '24px', color: '#1A73E8', fontWeight: 'bold' }}>
                This test has no report
            </h1>
        </div>
    );
};

export default ReportPage;

const TermsText = () => {
    return (
        <div className='scrollable-modal-content'>
            <h2>Disclaimer and Limitation of Liability</h2>
            <p><strong>BatteryCycle Limited</strong></p>
            <p>Registered Address: 3 Melrose Avenue, Fairview, Dublin 3, D03A4Y0, Ireland</p>
            <p>Company Registration Number: 763071</p>

            <h3>1. Accuracy of Test Results</h3>
            <p>
                BatteryCycle Limited ("BatteryCycle") provides its battery health assessment tools and software ("BatteryCycle Software") on an "as is" and "as available" basis. BatteryCycle's assessments are based on data gathered during a charging session and compared against a proprietary digital twin model of the battery. While BatteryCycle endeavors to provide accurate and reliable assessments, the results generated are inherently dependent on the data collected during the specific charging session and the assumptions within the digital twin model. As such, BatteryCycle does not guarantee, represent, or warrant that the test results will be accurate, complete, or error-free.
            </p>

            <h3>2. Disclaimer of Warranties</h3>
            <p>
                To the fullest extent permitted by Irish law, BatteryCycle disclaims all warranties, express or implied, including but not limited to any implied warranties of merchantability, fitness for a particular purpose, or non-infringement. No oral or written information or advice given by BatteryCycle or its authorised representatives shall create any warranty not expressly stated herein.
            </p>

            <h3>3. Limitation of Liability</h3>
            <p>
                BatteryCycle shall not be liable for any direct, indirect, incidental, special, consequential, or punitive damages, including but not limited to loss of profits, loss of data, or other intangible losses, arising out of or related to your use of, or inability to use, the BatteryCycle Software, even if BatteryCycle has been advised of the possibility of such damages. BatteryCycle's total liability for any claim arising out of or relating to these terms shall not exceed the amount paid by you, if any, to BatteryCycle for the specific service provided.
            </p>

            <h3>4. No Liability for Data Variations</h3>
            <p>
                BatteryCycle expressly disclaims any liability for variations in results that may occur due to factors outside its control, including but not limited to environmental conditions during the charging session, the specific charging infrastructure used, and variations in the vehicle's condition. BatteryCycle does not independently verify the data provided during the charging session beyond its internal processes and is not responsible for any inaccuracies or omissions in such data.
            </p>

            <h3>5. Governing Law</h3>
            <p>
                These terms and any dispute or claim (including non-contractual disputes or claims) arising out of or in connection with them or their subject matter or formation shall be governed by and construed in accordance with the laws of Ireland. The courts of Ireland shall have exclusive jurisdiction to settle any dispute or claim arising out of or in connection with these terms.
            </p>

            <h3>6. Severability</h3>
            <p>
                If any provision of this disclaimer is found to be invalid or unenforceable by a court of competent jurisdiction, such provision shall be severed from the remainder of this disclaimer, which shall remain in full force and effect.
            </p>
        </div>
    );
}

export default TermsText;
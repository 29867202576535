import { Row, Col } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import { Formik, Field } from 'formik';
import { LinkContainer } from 'react-router-bootstrap';
import ChevronBackward from '../assets/chevron_backward.png';
import homeimg from '../assets/homeimg.png';
import './ForgotPassword.css';

const ForgotPassword = () => {

    const handleSubmit = () => {
    };

    return (
        <Row className="rbh-container ">
            <Col>
                <Row className="text-start align-items-start">
                    <Col md={12} lg={6} className="rbh-form-container login-container">
                        <div className='back-login-wrapper'>
                            <LinkContainer to="/login">
                                <img
                                    src={ChevronBackward}
                                    alt="chevron icon"
                                    className='back-login-icon'
                                />
                            </LinkContainer>
                            <p className='back-login-wrappe-text' style={{ cursor: "pointer" }}>Back to Login page</p>
                        </div>

                        <div className="forget-password-text-wrapper">
                            <h1 className='forget-password-header'>Forgot password?</h1>
                            <p className='forget-password-text'>Enter your registered email address to receive password reset instructions</p>
                        </div>

                        <Formik
                            initialValues={{ email: '' }}
                            onSubmit={handleSubmit}
                        >
                            {({ handleSubmit }) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                    <Form.Group className="mb-3 form-group-custom">
                                        <Form.Label htmlFor="email" className="auth-form-label">Email</Form.Label>
                                        <Field
                                            id="email"
                                            autoComplete="email"
                                            type="email"
                                            name="email"
                                            className="form-control auth-form-control"
                                            placeholder="Enter your email address"
                                        />
                                    </Form.Group>
                                    <button type="submit" className="btn btn-primary w-100">Send Reset Link</button>
                                </Form>
                            )}
                        </Formik>
                    </Col>
                </Row>
            </Col>
            <Col className='d-none d-lg-block'>
                <div className='layout_image_wrapper'>
                    <img src={homeimg} alt="home page image" />
                </div>
            </Col>
        </Row>
    );
}

export default ForgotPassword;
